main > .container,
main > .container-fluid {
    padding: 70px 15px 20px;
}

.footer {
    background-color: #f5f5f5;
    font-size: .9em;
    height: 60px;
}

.footer > .container,
.footer > .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
}

.not-set {
    color: #c55;
    font-style: italic;
}

/* add sorting icons to gridview sort links */
a.asc:after, a.desc:after {
    content: '';
    left: 3px;
    display: inline-block;
    width: 0;
    height: 0;
    border: solid 5px transparent;
    margin: 4px 4px 2px 4px;
    background: transparent;
}

a.asc:after {
    border-bottom: solid 7px #212529;
    border-top-width: 0;
}

a.desc:after {
    border-top: solid 7px #212529;
    border-bottom-width: 0;
}

.grid-view {
    & th,
    & td:last-child {
        white-space: nowrap;
    }
    & .filters input,
    & .filters select {
        min-width: 50px;
    }
}

.hint-block {
    display: block;
    margin-top: 5px;
    color: #999;
}

.error-summary {
    color: #a94442;
    background: #fdf7f7;
    border-left: 3px solid #eed3d7;
    padding: 10px 20px;
    margin: 0 0 15px 0;
}

.required > label:after {
    color: #e00;
    content: "(必須)";
}


/** 標準の追加カスタマイズ **/
html {
    font-size: 14px;
}

main {
    & a:not(.btn),
    & a.btn-link {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

/** 固有の共通スタイル **/
.readmore-btn,
.readless-btn {
    display: inline-block;
    border: none;
    background: none;
    font-size: 0.8em;
    padding: 0.8rem 0 0.5em 1em;
    text-align: left;
}
