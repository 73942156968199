.diff-detail {
    overflow-y: hidden;
}

.params-selected {
    display: inline-block;
    margin: 5px 0;
    padding-left: 0;
}
.params-selected > li {
    float:left;
    display: inline;
    list-style-type: none;
    margin: 5px;
    padding: 5px;
}

.user-cookie > span,
.user-authkey > span {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    width: 8ch;
}
a.search-param-cfduid,
a.search-param-authkey {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
}
a.search-param-authkey {
    width: 28ch;
}
a.search-param-cfduid {
    width: 24ch;
}

.diff-body {
    word-break: break-all;
    margin: 0 0 0 14px;
}

.diff-body > div {
    line-height: 22px;
}

.highlight {
    background: #ffff00;
    padding: 3px;
}

.diff-container .diff-block{
    margin-top: 35px;
}

div.wiki-info,
ul.diff-info  {
    padding-left: 0;
}

div.wiki-info　 {
    & .wiki-info-action-menu {
        display: inline;
        & ul {
            display: inline;
            & li {
                display: inline;
                white-space: nowrap;
                margin-right: 10px;
            }
        }

        & .operation-dropdown {
            border: none;
            background: none;
            padding: 2px 8px;
            white-space: nowrap;
            margin-right: 20px;
            margin-left: 10px;
        }
    }

    @media only screen and (max-width: 767px) {
        display: flex;
        justify-content: space-between;

        & .wiki-info-action-menu {
            display: block;
            margin-left: 8px;
            cursor: pointer;
            position: relative;
            & a {
                vertical-align: middle;
            }
            & ul {
                display: none;
                position: absolute;
                z-index: 999;
                margin: 0;
                right: 0;
                top: -6px;
                padding: 30px;
            }
        }

        & .wiki-info-action-menu:hover ul {
            display: block;
        }

        & .wiki-info-action-menu ul li {
            background: #FFFFFF;
            padding: 8px 10px;
            margin-bottom: -8px;
            border-left: 1px solid silver;
            border-right: 1px solid silver;
            display: block;
            white-space: nowrap;
            margin-right: 5px;

            &:first-child {
                border-top: 1px solid silver;
            }
            &:last-child {
                border-bottom: 1px solid silver;
            }
        }
    }
}

p.wiki-name {
    margin-bottom: 10px;
    border-left: 8px solid;
    border-bottom: 0;
    border-top: 0;
    border-right: 0;
    border-color: #337ab7;
    padding: 2px 0 2px 7px;
    display: inline;
    align-items: center;

    & .wiki-name-filter {
        margin-left: 8px;
    }
}


ul.diff-info {
    margin-left: 15px;
    margin-bottom: 15px;

    & > li {
        display: inline-block;
    }

    & > li {
        margin-right: 8px;
    }
}

.diff-info {
    & > li[class^="user-"] {
        margin: 0 0.5em 0 0;
        white-space: nowrap;
        font-size: smaller;
        color: #aaa;
    }

    & .diff-log-filter {
        margin-right: 12px;
    }
}
a.disabled,
ul.date-pager > li.disabled > a,
ul.date-pager-disabled > li > a {
    pointer-events: none;
    opacity: .65;
}

.diff-log-header,
.diff-detail-header {
    margin-bottom: 25px;

    & > h1 {
        font-size: 26px;
    }
}

.diff-detail-header .open-wiki-page > i {
    font-size: 21px;
}

.fa-external-link {
    vertical-align: 10%;
}

@media only screen and (max-width: 767px) {
    .diff-log-header,
    .diff-detail-header {
        margin-bottom: 18px;

        & > h1 {
            font-size: 20px;
        }
    }

    .diff-detail-header .open-wiki-page > i {
        font-size: 16px;
    }
}

.diff-detail-header .open-wiki-page {
    padding: 0;
}

.diff-detail-body > .diff-detail > .diff-body {
    padding: 0;
}

.diff-detail-body {
    margin-bottom: 20px;

    & .diff-body {
        word-break: break-all;
        margin: 0;
    }
}


.diff-container .readmore-btn,
.diff-container .readless-btn {
    padding: 8px 0 0.5em 10px;
}

.diff-detail-body .readmore-btn,
.diff-detail-body .readless-btn {
    padding: 8px 0 0.5em;
}

.diff-detail-data table {
    & th {
        white-space: nowrap;
    }
    & td {
        word-break: break-all;
    }
}

@media only screen and (max-width: 767px) {
    .log-detail-header > h1 {
        font-size: 20px;
    }
}

.summary {
    margin: 0 0 15px;
}

.label-plugin {
    padding: .1em .3em .2em;
    font-size: 75%;
    color: #fff;
}

.tooltip-inner {
    max-width: 330px;
    color: #fff;
    background-color: #717171;
}

.tooltip.top .tooltip-arrow {
    border-top-color: #717171;
}

.pager-month-char, .pager-date-char {
    font-size: 9px;
    margin: 0 1px;
}

ul.wiki-front-page-url {
    padding-left: 0;

    & > li {
        display: inline-block;
        margin-right: 10px;
    }

    & i {
        font-size: 14px;
    }
}


.btn-suspend {
    padding-bottom: 10px;
}

.star-bright {
    color: #FF5600;
}

.star-darken {
    color: #c0c0c0;
}

.diff-detail-data table i {
    font-size: 12px;
}

a.icon-wiki-detail i {
    font-size: 16px;
}

.select-all {
    user-select: all;
}

.edit-btn-container {
    margin: 20px 0 10px;

    & > button > i {
        font-size: 10px;
    }
}

/* _list-element */
.user-identicon {
    display: inline-block;
    width: 15px;
    height: 15px;

    & > img {
        vertical-align: text-bottom;
    }
}

/* track-user */
.diff-container {
    margin-top: 20px;
}
.tracking-params {
    display: inline-block;
    margin: 10px 0;
    padding-left: 0;

    & > li {
        float:left;
        display: inline;
        list-style-type: none;
        margin: 2px;
        padding: 2px;
    }
}

/* after */
/*TODO*/
.diff-log-list {
    & .before-diff {
        opacity: 0.5;
    }
    & .diff-container .after-diff + .before-diff::before {
        border-top: 2px dotted;
        display: block;
        content: "";
        margin: 10px;
    }
}

.new-arrival-interrupt {
    margin-bottom: -35px;
}
